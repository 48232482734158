import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlag } from '@openfeature/react-sdk';
import FeatureFlagProvider from '../providers/FeatureFlagProvider';
import Spinner from '../src/connect/components/Spinners/Spinner';

// This component supports string, boolean and number feature flag types
// NOTE: the typeof *defaultFlagValue* will determine the type of feature flag hook to use
//   https://github.com/open-feature/js-sdk/blob/main/packages/react/src/evaluation/use-feature-flag.ts#L66
// currently only allows for two possible states, enabled/disabled
//   could be expanded to support more; e.g. group_a, group_b, group_c, group_d
const Redirector = ({
  featureFlag,
  featureFlagTestCase,
  defaultFlagValue,
  enabledUrl,
  disabledUrl,
  fallbackUrl,
  showSpinner = false,
  spinnerDelayMs = 0,
  timeoutMs = 2000
}) => {
  const [displaySpinner, setDisplaySpinner] = useState(false);
  useEffect(() => {
    let spinnerTimeoutId;
    if (showSpinner) {
      spinnerTimeoutId = setTimeout(() => {
        setDisplaySpinner(true);
      }, spinnerDelayMs);
    }
    return () => clearTimeout(spinnerTimeoutId);
  }, [showSpinner, spinnerDelayMs]);

  const flagValue = useFlag(featureFlag, defaultFlagValue);

  useEffect(() => {
    const handleRedirect = url => {
      if (window.location.href !== url) {
        window.location.href = url;
      }
    };

    const timeoutId = setTimeout(() => {
      handleRedirect(fallbackUrl || disabledUrl);
    }, [timeoutMs]);

    if (flagValue !== undefined) {
      clearTimeout(timeoutId);
      const redirectUrl =
        flagValue === featureFlagTestCase ? enabledUrl : disabledUrl;
      handleRedirect(redirectUrl);
    }

    return () => clearTimeout(timeoutId);
  }, [flagValue]);

  return (
    <div>
      {displaySpinner && (
        <Spinner
          size={70}
          containerClassName="cx_l--position-relative cx_l--flex cx_l--flex-justify-center cx_l--margin-bottom-36"
        />
      )}
    </div>
  );
};

const FeatureFlagRedirector = props => {
  return (
    <FeatureFlagProvider>
      <Redirector {...props} />
    </FeatureFlagProvider>
  );
};

const redirectorPropTypes = {
  featureFlag: PropTypes.string.isRequired,
  featureFlagTestCase: PropTypes.string.isRequired,
  defaultFlagValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]).isRequired,
  enabledUrl: PropTypes.string.isRequired,
  disabledUrl: PropTypes.string.isRequired,
  fallbackUrl: PropTypes.string,
  showSpinner: PropTypes.bool,
  spinnerDelayMs: PropTypes.number,
  timeoutMs: PropTypes.number
};

Redirector.propTypes = redirectorPropTypes;
FeatureFlagRedirector.propTypes = redirectorPropTypes;

export default FeatureFlagRedirector;
