import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addDays, subDays } from 'date-fns';
import Button from '../src/components/Button/Button';
import DateInput from '../src/components/DateInput/DateInput';
import Modal from '../src/components/Modals/Modal';

const PricingCsvEdit = props => {
  const {
    authenticityToken,
    elementId,
    id,
    organizationId,
    status,
    effectiveAt,
    expiresAt,
    allowSelectToday,
    hideEndDate
  } = props;

  const [startDate, setStartDate] = useState(effectiveAt);
  const [endDate, setEndDate] = useState(expiresAt);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleStartDateChange = date => {
    setStartDate(date?.toString());
  };

  const handleEndDateChange = date => {
    setEndDate(date?.toString());
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('id', id);

    const path = organizationId
      ? `/admin/organizations/${organizationId}/pricing/${id}`
      : '/admin/pricing/update';
    const method = organizationId ? 'PUT' : 'POST';

    try {
      const response = await fetch(path, {
        method,
        headers: {
          'X-CSRF-Token': authenticityToken
        },
        body: formData
      });

      response.json().then(jsonResponse => {
        if (response.status === 200) {
          location.reload();
        } else {
          setErrors(jsonResponse.errors);
          setIsSubmitting(false);
        }
      });
    } catch (error) {
      setErrors([error.message]);
      setIsSubmitting(false);
    }
  };

  const handleCloseErrorsModal = () => {
    setErrors([]);
  };

  const handleCancel = () => {
    const element = document.getElementById(elementId);
    if (element) {
      element.style.display = 'none';
    }

    setStartDate(effectiveAt);
    setEndDate(expiresAt);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center">
        <div className="flex-shrink-0 mr-2">First day:</div>
        <div className="w-48 mr-8">
          <DateInput
            name="start_date"
            id="start_date"
            label=""
            value={startDate}
            minDate={allowSelectToday ? new Date() : addDays(new Date(), 1)}
            maxDate={organizationId ? subDays(new Date(endDate), 1) : null}
            onChange={handleStartDateChange}
            invisibleLabel
            required
            disabled={status === 'Active'}
          />
        </div>
        {!hideEndDate && (
          <>
            <div className="flex-shrink-0 mr-2">Last day:</div>
            <div className="w-48 mr-8">
              <DateInput
                name="end_date"
                id="end_date"
                label=""
                value={endDate}
                minDate={addDays(new Date(startDate), 1)}
                onChange={handleEndDateChange}
                invisibleLabel
                required
              />
            </div>
          </>
        )}
        <div className="mr-2">
          <Button type="submit" className="btn-primary" disabled={isSubmitting}>
            Save
          </Button>
        </div>
        <div>
          <Button onClick={handleCancel} className="btn-link">
            Cancel
          </Button>
        </div>
      </div>

      <Modal
        id={`errors-modal-pricing-event-${id}`}
        isOpen={errors && errors.length > 0}
        onClose={handleCloseErrorsModal}
      >
        <div className="p-10">
          <div className="mb-8">
            <h3 className="cx_t cx_t--s cx_t--blue">Edit Error</h3>
          </div>
          <div>{errors.join(', ')}</div>
          <div className="flex justify-end mt-16">
            <Button onClick={handleCloseErrorsModal} className="btn-primary">
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </form>
  );
};

PricingCsvEdit.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  effectiveAt: PropTypes.string.isRequired,
  expiresAt: PropTypes.string,
  allowSelectToday: PropTypes.bool,
  hideEndDate: PropTypes.bool
};

export default PricingCsvEdit;
