import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import createConsumer from '../../../channels/consumer';
import { format } from 'date-fns-tz';
import CountDownTimer from '../CountdownTimer/CountdownTimer';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon';

const OrderHistory = props => {
  const [orderHistoryRecords, setOrderHistoryRecords] = useState(
    props.orderHistoryRecords
  );

  useEffect(() => {
    const orderHistoryChannel = createConsumer.subscriptions.create(
      {
        channel: 'OrderHistoryChannel',
        order_id: props.orderId
      },
      {
        received: data => {
          setOrderHistoryRecords(data);
        }
      }
    );

    return () => {
      orderHistoryChannel.unsubscribe();
    };
  }, []);

  const formattedTimeAndDate = dateObj => {
    return `${format(dateObj, 'M/d/y h:mma zzz')}`;
  };

  function renderUserName(record) {
    const { initiated_by_user, name_override, subtext } = record['table'];
    if (name_override) return name_override;
    if (subtext === 'This delivery was completed offline') return null;
    return (
      <a href={`/users/${initiated_by_user.id}`}>
        {initiated_by_user.given_name} {initiated_by_user.family_name}
      </a>
    );
  }

  const isBundledHistory = record => {
    if (
      record['table'].text === 'Unbundled' ||
      record['table'].text === 'Added to bundle' ||
      record['table'].text === 'Queued for bundling' ||
      record['table'].text === 'Removed From Bundle Queue' ||
      record['table'].text === 'On the available tab in bundle'
    )
      return true;
  };

  const displayTimer = record => {
    return (
      record['table'].text === 'Queued for bundling' ||
      record['table'].text === 'On the available tab in bundle'
    );
  };

  const filteredOrderHistoryRecords = orderHistoryRecords.filter(record => {
    if (
      record['table'].text === 'Dispatch Comment' &&
      !props.viewOrderComment
    ) {
      return false;
    } else if (
      (record['table'].text === 'Delivery Bonus Updated' ||
        record['table'].text === 'Delivery Bonus Removed') &&
      !props.viewAdHocDriverBonus
    ) {
      return false;
    } else if (
      (record['table'].text === 'Flag added' ||
        record['table'].text === 'Flag removed') &&
      !props.flagOrder
    ) {
      return false;
    } else if (
      (record['table'].text === 'Joined network' ||
        record['table'].text === 'Network changed') &&
      !props.viewNetworkDetails
    ) {
      return false;
    } else if (isBundledHistory(record) && !props.viewBundleHistory) {
      return false;
    } else if (
      record['table'].type === 'driver_notification' &&
      !props.viewDriverNotificationHistory
    ) {
      return false;
    } else if (
      !props.isInternalUser &&
      record['table'].internal_record === true
    ) {
      return false;
    } else {
      return record;
    }
  });

  const getOrderBundledLinks = ids => {
    return ids.split(',').map(id => {
      return (
        <a key={id} href={'/orders/' + id}>
          #{id},
        </a>
      );
    });
  };

  return (
    <div>
      <h3>Order History</h3>
      <ul className="list-group">
        {filteredOrderHistoryRecords &&
          filteredOrderHistoryRecords.map((record, i) => {
            return (
              <li
                className={
                  'list-group-item clearfix' +
                  (record['table'].internal_record === true
                    ? ' internal-only'
                    : '') +
                  (record['table'].delivery_level === true
                    ? ' list-group-item-indented'
                    : '') +
                  (record['table'].subtext ===
                  'This delivery was completed offline'
                    ? ' internal-only'
                    : '')
                }
                key={i}
              >
                <div className="row">
                  <div className="col-sm-10">
                    <div>
                      <span className="bold-text">{record['table'].text}</span>
                      {record['table'].smart_order === true &&
                        props.smartOrderLabelPolicy === true && (
                          <span className="label ml-2 bg-green-800">SMART</span>
                        )}
                      {record.table.info_bubble?.show && (
                        <Tooltip
                          text={
                            <div className="text-left">
                              {record.table.info_bubble.before_text}
                              <b>{record.table.info_bubble.distance_text}</b>
                            </div>
                          }
                          placement="bottom"
                          className="ml-2 cursor-pointer"
                          width={220}
                        >
                          <span
                            className="text-gray-500 inline-block align-baseline"
                            style={{ verticalAlign: -4 }}
                          >
                            <Icon name="info" size={18} />
                          </span>
                        </Tooltip>
                      )}
                    </div>
                    {record['table'].subtext !== undefined && (
                      <div className="text-muted break-words">
                        {displayTimer(record) ? (
                          <div>
                            Time remaining:{' '}
                            <CountDownTimer
                              className="md:w-1/2"
                              milliseconds={record['table'].subtext}
                            />
                            {record['table'].bundle_id && (
                              <div>#{record['table'].bundle_id}</div>
                            )}
                          </div>
                        ) : record['table'].text === 'Added to bundle' ? (
                          <div>
                            <div>#{record['table'].bundle_id}</div>
                            Bundled with{' '}
                            {getOrderBundledLinks(record['table'].subtext)}
                          </div>
                        ) : (
                          record['table'].subtext
                        )}
                      </div>
                    )}
                    {record['table'].text === 'Canceled' && (
                      <div className="text-muted break-words">
                        {props.cancellationReason}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-2">
                    <div className="text-right text-muted">
                      {formattedTimeAndDate(
                        new Date(record['table'].occurred_at)
                      )}
                    </div>
                    {record['table'].text === 'Order created' &&
                      record['table'].initiated_by_user && (
                        <>
                          <div className="text-right">
                            <a
                              href={
                                '/users/' + record['table'].initiated_by_user.id
                              }
                            >
                              {record['table'].initiated_by_user.given_name}{' '}
                              {record['table'].initiated_by_user.family_name}
                            </a>
                          </div>
                          <div className="text-right text-muted">
                            {record['table'].initiated_by_user.phone_number}
                          </div>
                        </>
                      )}
                    {record['table'].text !== 'Order created' &&
                      record['table'].initiated_by_user && (
                        <div className="text-right">
                          {renderUserName(record)}
                        </div>
                      )}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

OrderHistory.propTypes = {
  orderId: PropTypes.number,
  cancellationReason: PropTypes.string,
  smartOrderLabelPolicy: PropTypes.bool,
  viewOrderComment: PropTypes.bool,
  viewAdHocDriverBonus: PropTypes.bool,
  flagOrder: PropTypes.bool,
  viewNetworkDetails: PropTypes.bool,
  viewBundleHistory: PropTypes.bool,
  viewDriverNotificationHistory: PropTypes.bool,
  isInternalUser: PropTypes.bool,
  orderHistoryRecords: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      subtext: PropTypes.string,
      info_bubble: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        before_text: PropTypes.string,
        distance_text: PropTypes.string
      }),
      initiated_by_user: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number,
          given_name: PropTypes.string,
          phone_number: PropTypes.string
        })
      ),
      occurred_at: PropTypes.string,
      delivery_level: PropTypes.string,
      internal_record: PropTypes.bool,
      name_override: PropTypes.string,
      smart_order: PropTypes.bool
    })
  )
};

export default OrderHistory;
