import React, { useState, useEffect, useCallback } from 'react';
import useUpdateEffect from '../src/hooks/useUpdateEffect';
import PropTypes from 'prop-types';
import FormRow from '../src/components/FormRow/FormRow';
import HiddenInput from '../src/components/HiddenInput/HiddenInput';
import OrganizationInput from '../src/components/OrganizationInput/OrganizationInput';
import BranchesInput from '../src/components/BranchesInput/BranchesInput';
import LoadingSpinner from '../src/components/LoadingSpinner/LoadingSpinner';
import Button from '../src/components/Button/Button';

const FilterOrdersByBranch = props => {
  const {
    authenticityToken,
    autoComplete,
    branchId,
    branchInputLabel,
    isInternalUser,
    onBranchChange,
    onClear,
    onOrgChange,
    orgId,
    orgInputLabel,
    orgName,
    shouldFetchBranchesOnMount,
    tree
  } = props;

  const [orgIdVal, setOrgIdVal] = useState(orgId?.toString());
  const [branchIdVal, setBranchIdVal] = useState(branchId?.toString() || '');
  const [orgTree, setOrgTree] = useState(tree);
  const [isLoading, setIsLoading] = useState(false);

  const handleOrgChange = (id, name) => {
    setOrgIdVal(id.toString());
    if (onOrgChange) {
      onOrgChange(id, name);
    }
  };

  const handleBranchChange = e => {
    setBranchIdVal(e.target.value);
    if (onBranchChange) {
      onBranchChange(e.target.value);
    }
  };

  const handleClear = useCallback(() => {
    setOrgIdVal(null);
    setBranchIdVal(null);
    setOrgTree(null);
    if (onOrgChange) {
      onOrgChange(null, '');
    }
    if (onBranchChange) {
      onBranchChange(null);
    }
  }, [onOrgChange, onBranchChange]);

  const fetchBranches = () => {
    if (!orgIdVal) return;

    setIsLoading(true);
    fetch(`/organizations/${orgIdVal}/branches`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      credentials: 'include'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setOrgTree(data);
        setIsLoading(false);
      })
      .catch(() => {
        setOrgTree(null);
        setBranchIdVal(null);
        setIsLoading(false);
      });
  };

  // Fetches when orgIdVal changes, including on mount
  useEffect(() => {
    if (shouldFetchBranchesOnMount) {
      fetchBranches();
    }
  }, [orgIdVal]);

  // Fetches when orgIdVal changes, but not on mount
  useUpdateEffect(() => {
    if (!shouldFetchBranchesOnMount) {
      fetchBranches();
    }
  }, [orgIdVal]);

  return (
    <>
      <HiddenInput
        name="organization_filter"
        value={isInternalUser ? orgIdVal : branchIdVal}
      />
      {isInternalUser && (
        <FormRow>
          <OrganizationInput
            id="organization-search"
            label={orgInputLabel ?? 'Filter by organization'}
            value={orgName}
            onSelect={handleOrgChange}
            autoComplete={autoComplete}
          />
        </FormRow>
      )}
      {isLoading && (
        <div className="mb-8">
          <LoadingSpinner />
        </div>
      )}
      {orgTree && (
        <FormRow>
          <BranchesInput
            className="col col-sm-8"
            id="sub_organization_filter"
            name="sub_organization_filter"
            label={branchInputLabel ?? 'Filter by branch'}
            placeholder="Select a branch..."
            tree={orgTree}
            value={branchIdVal || ''}
            onChange={handleBranchChange}
          />
        </FormRow>
      )}
      {onClear && (
        <div className="mt-6">
          <Button onClick={handleClear}>Clear</Button>
        </div>
      )}
    </>
  );
};

FilterOrdersByBranch.propTypes = {
  authenticityToken: PropTypes.string,
  autoComplete: PropTypes.string,
  branchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  branchInputLabel: PropTypes.string,
  isInternalUser: PropTypes.bool,
  onBranchChange: PropTypes.func,
  onClear: PropTypes.func,
  onOrgChange: PropTypes.func,
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orgInputLabel: PropTypes.string,
  orgName: PropTypes.string,
  shouldFetchBranchesOnMount: PropTypes.bool,
  tree: PropTypes.object
};

export default FilterOrdersByBranch;
