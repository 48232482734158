import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CustomCsvUploader from '../src/components/CustomCsvUploader/CustomCsvUploader';
import DateInput from '../src/components/DateInput/DateInput';
import PricingCsvCustomModal from './PricingCsvCustomModal';
import Button from '../src/components/Button/Button';
import MarketplaceMultipliersTable from './MarketplaceMultipliersTable';
import MarketplacePricingTable from './MarketplacePricingTable';
import LoadingSpinner from '../src/components/LoadingSpinner/LoadingSpinner';
import formattedDate from '../src/utils/formattedDate.js';

const PricingCsvUpload = props => {
  const {
    authenticityToken,
    csvType,
    currentEndDate,
    currentRates,
    currentStartDate,
    handleWarnings,
    organizationId,
    pricingEventId,
    isActive,
    useFileSearch,
    setActiveOption,
    pricingConfigs,
    allowSelectToday
  } = props;

  const getFutureDate = (currentDate, number) => {
    const futureDate = new Date();
    futureDate.setUTCDate(
      currentDate
        ? new Date(Date.parse(currentDate)).getUTCDate() + number
        : futureDate.getUTCDate() + number
    );
    return futureDate;
  };

  const [data, setData] = useState(null);
  const [csv, setCsv] = useState(null);
  const [isCsvValid, setIsCsvValid] = useState(false);
  const minDate = allowSelectToday
    ? new Date()
    : currentStartDate
    ? new Date(parseInt(currentStartDate))
    : getFutureDate(null, 1);
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(
    currentEndDate ? new Date(parseInt(currentEndDate)) : getFutureDate(null, 2)
  );

  const [showCsvErrorModal, setShowCsvErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [priceConfigId, setPriceConfigId] = useState(null);
  const [showPricingConfigModal, setShowPricingConfigModal] = useState(false);

  const [showDeactivatePricingModal, setShowDeactivatePricingModal] = useState(
    false
  );

  const handlePricingConfigSelect = configId => {
    if (!configId) return;

    setPriceConfigId(configId);
    setStartDate(
      pricingConfigs.find(config => config.pricing_change_id === configId)
        .effective_at
    );
    setEndDate(
      pricingConfigs.find(config => config.pricing_change_id === configId)
        .expires_at
    );
    setShowPricingConfigModal(false);
  };

  const handleDeactivateAndContinue = e => {
    handleSubmit(e, true);
  };

  const getPromptText = csvType => {
    if (csvType === 'market_multipliers') {
      return 'Upload Marketplace Multipliers CSV';
    } else if (csvType === 'default_pricing_config') {
      return 'Upload Base Pricing CSV';
    }
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleSuccess = responseData => {
    handleWarnings(responseData?.warnings && responseData?.warnings.length > 0);
    setIsLoading(false);
    setData(responseData);
    setIsCsvValid(true);

    if (
      csvType === 'contractual_multipliers' &&
      !priceConfigId &&
      pricingConfigs.length > 1
    ) {
      setPriceConfigId(pricingConfigs[0].pricing_change_id);
      setShowPricingConfigModal(true);
    } else if (
      csvType === 'contractual_multipliers' &&
      !priceConfigId &&
      pricingConfigs.length === 1
    ) {
      setPriceConfigId(pricingConfigs[0].pricing_change_id);
    }
  };

  const handleFailure = errorData => {
    setIsLoading(false);
    setData(errorData);
    setShowCsvErrorModal(true);
    setIsCsvValid(false);
  };

  const handleCsv = formData => {
    setCsv(formData.get('csv_file'));
    formData.append('csv_type', csvType);
  };

  const handleFetch = formData => {
    handleCsv(formData);
    if (startDate) formData.append('start_date', startDate);
    if (endDate) formData.append('end_date', endDate);
    if (organizationId) formData.append('organization_id', organizationId);
  };

  const handleSubmit = (e, forceUpload = false) => {
    e.preventDefault();

    setSubmitDisabled(true);

    const formData = new FormData();

    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('organization_id', organizationId);
    formData.append('csv_file', csv);
    formData.append('csv_type', csvType);
    formData.append('force_upload', forceUpload);
    if (pricingEventId) formData.append('id', pricingEventId);
    if (priceConfigId) formData.append('pricing_change_id', priceConfigId);

    const path = getPathForSubmit(csvType, pricingEventId, organizationId);
    try {
      fetch(path, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': authenticityToken
        }
      }).then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.conflict) {
            setShowDeactivatePricingModal(true);
            setSubmitDisabled(false);
          } else if (response.status !== 200) {
            setData(jsonResponse);
            setShowCsvErrorModal(true);
            setSubmitDisabled(false);
          } else {
            location.reload();
          }
        });
      });
    } catch (error) {
      setSubmitDisabled(false);
    }
  };

  const handleCancel = () => {
    setData(null);
    setCsv(null);
    setIsCsvValid(false);
    setStartDate(
      currentStartDate
        ? new Date(parseInt(currentStartDate))
        : getFutureDate(null, 1)
    );
    setEndDate(
      currentEndDate
        ? new Date(parseInt(currentEndDate))
        : getFutureDate(null, 2)
    );
    handleWarnings(false);
    setIsLoading(false);
    setSubmitDisabled(false);
    setActiveOption?.('');
  };

  const clearModal = () => {
    setShowCsvErrorModal(false);
  };

  const didMount = useRef(false);

  useEffect(() => {
    async function fetchData() {
      if (!pricingEventId) {
        return;
      }
      if (data?.formatted_pricing_data) {
        return;
      }
      if (didMount.current) {
        const formData = new FormData();
        formData.append('pricing_event_id', pricingEventId);
        return fetch('pricing/pricing_event_data', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': authenticityToken
          },
          body: formData
        }).then(response => {
          response.json().then(jsonResponse => {
            setData(jsonResponse);
          });
        });
      } else didMount.current = true;
    }
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (priceConfigId && csvType === 'contractual_multipliers') {
      const matchingConfig = pricingConfigs.find(
        config => config.pricing_change_id === priceConfigId
      );
      if (matchingConfig) {
        setStartDate(matchingConfig.effective_at);
        setEndDate(matchingConfig.expires_at);
      }
    }
  }, [priceConfigId, csvType]);

  // Update 'endDate' when 'startDate' changes, setting it to one day ahead.
  useEffect(() => {
    if (csvType === 'contractual') {
      setEndDate(getFutureDate(startDate, 1));
    }
  }, [startDate]);

  return (
    <>
      {useFileSearch && isActive ? (
        <div>
          <div className="flex flex-col gap-4 items-center">
            <div id={`custom-csv-uploader-${csvType}`}>
              <CustomCsvUploader
                authenticityToken={authenticityToken}
                onFail={handleFailure}
                onFetch={handleCsv}
                onLoading={setIsLoading}
                onSuccess={handleSuccess}
                path={getPathForValidation(csvType, organizationId)}
                showDownloadTemplate={false}
                key={csvType}
                id={csvType}
                useFileButton
                onFileSelect={setPriceConfigId}
              />
            </div>
            {csv && isCsvValid && (
              <>
                <div className="flex flex-row items-center">
                  <div className="flex-shrink-0 mr-2">First day:</div>
                  <DateInput
                    name="start_date"
                    id="start_date"
                    label=""
                    value={startDate.toString()}
                    required
                    autoComplete="off"
                    customErrorMessage="Please enter a start date"
                    showRequiredIndicator={false}
                    minDate={minDate}
                    onChange={handleStartDateChange}
                    invisibleLabel
                    customClass="mb-0"
                    disabled={csvType === 'contractual_multipliers'}
                  />
                </div>
                {organizationId && (
                  <div className="flex flex-row items-center">
                    <div className="flex-shrink-0 mr-2">Last day:</div>
                    <DateInput
                      name="end_date"
                      id="end_date"
                      label=""
                      value={endDate.toString()}
                      required={!!organizationId}
                      autoComplete="off"
                      customErrorMessage="Please enter an end date"
                      showRequiredIndicator={false}
                      minDate={getFutureDate(startDate, 1)}
                      onChange={handleEndDateChange}
                      invisibleLabel
                      customClass="mb-0"
                      disabled={csvType === 'contractual_multipliers'}
                    />
                  </div>
                )}
                <div className="flex justify-end mt-3">
                  <Button onClick={handleCancel} className="btn-link">
                    Cancel
                  </Button>
                  <Button
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                    className="btn-primary"
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div>First day:</div>
            <DateInput
              name="start_date"
              id="start_date"
              label={''}
              value={startDate.toString()}
              required
              autoComplete="off"
              customErrorMessage={'Please enter a start date'}
              showRequiredIndicator={false}
              minDate={minDate}
              onChange={handleStartDateChange}
              invisibleLabel
              customClass={'mb-0'}
            />
            {organizationId && (
              <>
                <div>Last day:</div>
                <DateInput
                  name="end_date"
                  id="end_date"
                  label={''}
                  value={endDate.toString()}
                  required={!!organizationId}
                  autoComplete="off"
                  customErrorMessage={'Please enter an end date'}
                  showRequiredIndicator={false}
                  minDate={getFutureDate(startDate, 1)}
                  onChange={handleEndDateChange}
                  invisibleLabel
                  customClass={'mb-0'}
                />
              </>
            )}
            <Button
              disabled={submitDisabled || !csv || !isCsvValid}
              onClick={handleSubmit}
              style={'primary'}
            >
              Submit
            </Button>
            <Button onClick={handleCancel} style={'link'}>
              Cancel
            </Button>
          </div>
          {!pricingEventId && !isLoading && !data?.formatted_pricing_data && (
            <div id={`custom-csv-uploader-${csvType}`} className="-my-16">
              <CustomCsvUploader
                authenticityToken={authenticityToken}
                onFail={handleFailure}
                onFetch={handleFetch}
                onLoading={setIsLoading}
                onSuccess={handleSuccess}
                promptText={getPromptText(csvType)}
                path="pricing/validate_pricing_csv"
                showDownloadTemplate={false}
                key={csvType}
                id={csvType}
              />
            </div>
          )}
          {isLoading && !data?.formatted_pricing_data && (
            <div className="-my-16">
              <div className="cx_l--height-280 cx_l--flex cx_l--flex-align-center cx_l--padding-right-20 cx_l--padding-left-20 cx_l--flex-column cx_l--flex-justify-center">
                <div className="cx_border--dashed cx_upload-csv">
                  <div className="cx_l--flex cx_l--flex-column cx_l--flex-align-center">
                    <span className="cx_fieldset__legend">
                      <LoadingSpinner />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {csvType === 'market_multipliers' &&
            data?.formatted_pricing_data &&
            Object.keys(data?.formatted_pricing_data).length !== 0 && (
              <div className="mt-9">
                <MarketplaceMultipliersTable
                  currentRates={currentRates}
                  marketsWithWarnings={data.warnings}
                  pricingData={data.formatted_pricing_data}
                />
              </div>
            )}
          {csvType === 'default_pricing_config' &&
            data?.formatted_pricing_data &&
            Object.keys(data?.formatted_pricing_data).length !== 0 && (
              <div
                className="mt-9"
                style={{ overflowX: 'scroll', display: 'block' }}
              >
                <MarketplacePricingTable
                  pricingData={data.formatted_pricing_data}
                />
              </div>
            )}
        </>
      )}
      <PricingCsvCustomModal
        isOpen={showCsvErrorModal}
        onClose={clearModal}
        header="Upload Error"
        text={
          data?.auth_error
            ? null
            : 'Could not upload your file. Please fix the following errors and try again:'
        }
        errors={data?.errors || data?.auth_error}
        primaryBtnOnClick={clearModal}
      />
      <PricingCsvCustomModal
        isOpen={showPricingConfigModal}
        onClose={() => setShowPricingConfigModal(false)}
        header="Link to Pricing"
        text='"Contractual Multipliers" must be associated with a "Contractual Pricing" table. Select which pricing to link it to:'
        errors={null}
        primaryBtnText="Link and Continue"
        primaryBtnOnClick={() => handlePricingConfigSelect(priceConfigId)}
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={() => setShowPricingConfigModal(false)}
      >
        <div className="flex flex-col">
          {(pricingConfigs || []).map((config, index) => (
            <div key={config.id} className="flex items-start space-x-3">
              <input
                type="radio"
                id={config.id}
                name="pricingConfig"
                value={config.id}
                defaultChecked={index === 0}
                onChange={() => setPriceConfigId(config.pricing_change_id)}
                className="form-radio mt-1 mb-2"
              />
              <label
                htmlFor={config.id}
                className="leading-tight text-left text-gray-800"
              >
                <span className="font-sans text-sm">
                  ({formatStatus(config.effective_at, config.expires_at)})
                  Contractual Pricing{' '}
                  {formattedDate(new Date(config.effective_at))} -{' '}
                  {formattedDate(new Date(config.expires_at))}
                </span>
              </label>
            </div>
          ))}
        </div>
      </PricingCsvCustomModal>
      <PricingCsvCustomModal
        isOpen={showDeactivatePricingModal}
        onClose={() => setShowDeactivatePricingModal(false)}
        header="Deactivate Conflicting Pricing?"
        primaryBtnText="Deactivate and Continue"
        primaryBtnOnClick={handleDeactivateAndContinue}
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={() => setShowDeactivatePricingModal(false)}
      >
        <div>
          You already have some pricing that is active or pending activation. If
          you continue, they will be removed.
          <br />
          <br />
          To review active or pending pricing, select “Cancel” and check the
          Custom Pricing Activity.
        </div>
      </PricingCsvCustomModal>
    </>
  );
};

PricingCsvUpload.defaultProps = {
  handleWarnings: () => {},
  useFileSearch: false
};

PricingCsvUpload.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  csvType: PropTypes.string.isRequired,
  currentEndDate: PropTypes.number,
  currentRates: PropTypes.object,
  currentStartDate: PropTypes.number,
  handleWarnings: PropTypes.func,
  organizationId: PropTypes.string,
  pricingEventId: PropTypes.string,
  isActive: PropTypes.bool,
  useFileSearch: PropTypes.bool,
  setActiveOption: PropTypes.func,
  pricingConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      effective_at: PropTypes.string.isRequired,
      expires_at: PropTypes.string,
      pricing_change_type: PropTypes.string.isRequired,
      changed_by_user: PropTypes.shape({
        name: PropTypes.string
      }),
      pricing_change_id: PropTypes.string
    })
  ),
  allowSelectToday: PropTypes.bool
};

export default PricingCsvUpload;

function getPathForValidation(csvType, organizationId) {
  if (
    csvType === 'default_pricing_config' ||
    csvType === 'market_multipliers'
  ) {
    return '/admin/pricing/validate_pricing_csv';
  } else if (csvType === 'contractual') {
    return `/admin/organizations/${organizationId}/pricing/validate_contractual_pricing`;
  } else if (csvType === 'contractual_multipliers') {
    return `/admin/organizations/${organizationId}/pricing/validate_contractual_multiplier`;
  }
  throw new Error(`Unexpected csvType: ${csvType}`);
}

function getPathForSubmit(csvType, pricingEventId, organizationId) {
  if (
    csvType === 'default_pricing_config' ||
    csvType === 'market_multipliers'
  ) {
    return pricingEventId ? '/admin/pricing/update' : '/admin/pricing';
  } else if (csvType === 'contractual') {
    return `/admin/organizations/${organizationId}/pricing/upload_contractual_pricing`;
  } else if (csvType === 'contractual_multipliers') {
    return `/admin/organizations/${organizationId}/pricing/upload_contractual_multiplier`;
  }
  throw new Error(`Unexpected csvType: ${csvType}`);
}

// format config status for modal
function formatStatus(effective_at, expires_at) {
  const now = new Date();
  const effectiveAt = new Date(effective_at);
  const expiresAt = new Date(expires_at);
  return now >= effectiveAt && now <= expiresAt ? 'Active' : 'Pending';
}
