import React from 'react';
import PropTypes from 'prop-types';
import { OpenFeatureProvider, OpenFeature } from '@openfeature/react-sdk';
import { PostHogClientProvider } from '@dispatch/posthog-openfeature-provider-js';

const posthogConfig = {
  token: window.posthogToken
};

const provider = new PostHogClientProvider(posthogConfig);

OpenFeature.setProvider(provider);

export default function FeatureFlagProvider({ children }) {
  return <OpenFeatureProvider>{children}</OpenFeatureProvider>;
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.node.isRequired
};
